@import '../../../../styles/variables';
@import '../../../../styles/mixin';
.desired-traits-section {
    background: linear-gradient(180deg, $background-color-lightblue 0px, #fff 28rem);
    .section-header {
        padding-bottom: 0;
    }
    .col-lg-4 {
        margin-top: 0.5rem;
        padding: 0 3.5rem;
        &:nth-child(n+4) {
            .card-body {
                border-top: $primary-border;
            }
        }
    }
    .card-body {
        border: 0;
        align-items: center;
        padding: 4rem 2.6rem 0.3rem !important;
        .item-icon {
            @include item-icon(7.5rem);
            margin-bottom: 2rem;
        }
        .p-content {
            text-align: left;
            ul {
                list-style: disc;
                margin: 0;
                li {
                    line-height: 1.2;
                    padding-bottom: 1rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .desired-traits-section {
        .col-lg-4 {
            &:nth-child(n+2) {
                .card-body {
                    // border-top: $primary-border;
                    border-top: none;
                    position: relative;
                    &::after {
                        content: "";
                        height: 1px;
                        width: 100%;
                        background: linear-gradient(270deg, #FFFFFF 0%, #E3E3E3 51.39%, #FFFFFF 100%);
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {
    .desired-traits-section {
        .col-lg-4 {
            &:nth-child(n+3) {
                .card-body {
                    // border-top: $primary-border;
                    border-top: none;
                    position: relative;
                    &::after {
                        content: "";
                        height: 1px;
                        width: 100%;
                        background: linear-gradient(270deg, #FFFFFF 0%, #E3E3E3 51.39%, #FFFFFF 100%);
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}