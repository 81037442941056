@import 'variables';
@mixin item-icon($size) {
    height: $size;
    width: $size;
    box-shadow: $primary-box-shadow-inset;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}


@mixin mobile-contact-section {
    &:before, &:after{
        background-position: -303px 106px;
        background-size: 500px;
    }
}

