@font-face {
    font-family: 'Gothic';
    src: url('/fonts/GOTHIC.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gothic';
    src: url('/fonts/GOTHICI.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Gothic';
    src: url('/fonts/GOTHICB.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gothic';
    src: url('/fonts/GOTHICBI.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}