.history-section {
    .section-content {
        max-width: 65rem;
        margin: auto;
        padding-top: 1rem;
    }
    .row {
        // display: grid;
        // grid-template-columns: max-content max-content max-content max-content;
        .col {
            padding: 1rem;
            line-height: 2.3rem;
            &:first-child {
                font-size: 1.6rem;
                font-weight: 600;
                max-width: 5.6rem;
                padding: 1rem; //grid-&umn-start: 1;
            }
            &:nth-child(2) {
                max-width: 4rem;
                font-size: 1.6rem;
                padding: 1rem; //grid-&umn-start: 2;
            }
            &:nth-child(3) {
                max-width: 4.5rem;
                padding: 1rem; // grid-&umn-start: 3;
            }
        }
        &.last-of-year {
            margin-bottom: 1rem;
            &::after {
                content: "";
                height: 1px;
                width: 100%;
                margin-top: 1rem;
                background: linear-gradient(270deg, #FFFFFF 0%, #E3E3E3 51.39%, #FFFFFF 100%);
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .history-section {
        .section-content {
            padding-top: 2.5rem;
            .row {
                .col {
                    padding-left: 2rem !important;
                    padding-right: 2rem !important;
                    &:first-child {
                        min-width: 100%;
                        text-align: center;
                        font-size: 2rem;
                        padding: 0.5rem;
                    }
                }
                &.year-name-sm {
                    .col {
                        &:first-child {
                            &::after {
                                content: "";
                                height: 1px;
                                width: 100%;
                                margin-top: 2rem;
                                margin-bottom: 0.5rem;
                                background: linear-gradient(270deg, #FFFFFF 0%, #E3E3E3 51.39%, #FFFFFF 100%);
                                display: block;
                            }
                        }
                    }
                }
                &.last-of-year {
                    margin-bottom: 4.5rem;
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}