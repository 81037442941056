.cp-section {
    p {
        margin-bottom: 5rem;
    }
  
    @media only screen and (max-width: 767px) {
        .section-header {
            text-align: center !important;
            .header-line {
                justify-content: center !important;
            }
        }
    }
    @media only screen and (max-width: 991.98px) and (min-width: 768px) {
        .section-header {
            text-align: center !important;
            .header-line {
                justify-content: center !important;
            }
        }
    }
}