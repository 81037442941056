@import "../../../../styles/variables";
@import "../../../../styles/mixin";

.cms-intro-section {
    .section-header {
        h6 {
            margin: 0;
        }
    }

    .section-content {
        h6 {
            font-weight: 500;
        }

        .world-map-bg {
            position: relative;

            img {
                &:first-child {
                    margin-top: -9.4rem;
                }

                &:not(:first-child) {
                    position: absolute;
                    left: 0;
                    top: 2rem;
                }

                &.cms-ja-img {
                    display: none;
                }

                @media only screen and (max-width: 991.98px) and (min-width: 768px) {
                    &:not(:first-child) {
                        top: 0;
                    }
                }
            }

            // background: url('/images/business/cms/world-map.svg');
        }
    }

    @media only screen and (max-width: 767px) {
        .world-map-bg {
            margin: 0 -1rem;

            img {
                &:first-child {
                    display: none;
                }

                &:not(:first-child) {
                    top: 5rem !important;
                    position: relative !important;
                    margin-bottom: 3rem;
                }
            }
        }
    }
}

html[lang="ja"] {
    .cms-intro-section {
        .cms-img {
            display: none !important;
        }

        .cms-ja-img {
            display: block !important;
        }
    }
}

.cms-the-simplest-section {
    z-index: 3;

    &::before,
    &::after {
        display: none;
    }

    .section-content {
        .col-lg-6 {
            &>div {
                margin-bottom: 5rem;
            }

            @media only screen and (max-width: 991.98px) and (min-width: 768px) {
                text-align: center;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .order-1 {
            padding: 0 !important;
        }
    }

    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
        .section-content {
            img {
                height: auto;
            }
        }
    }
}

.cms-afe-section {
    position: relative;
    padding-top: 29.5rem;
    padding-bottom: 15rem;
    margin-top: -22.5rem;
    background: url("/images/home/section-line.svg") center -1px no-repeat,
        linear-gradient(180deg, $background-color-lightblue 0px, #fff 55rem);
    background-size: auto, 100%;

    &::before,
    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: url("/images/email-bg.svg") -418px 0 no-repeat;
        display: block !important;
        background-size: 878px;
        border: none;
        z-index: -1;
    }

    &::before {
        transform: rotateY(180deg);
    }

    &::after {
        transform: none;
    }

    @media only screen and (max-width: 767px) {
        @include mobile-contact-section;
    }

    @media only screen and (max-width: 991.98px) and (min-width: 768px) {

        &::before,
        &::after {
            background-position: -527px 0;
        }

        .section-content {
            max-width: 42.5rem;
        }
    }
}