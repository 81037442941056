@import "../../../styles/variables";
@import "../../../styles/mixin";

.footer {
    background-color: #fafcff;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 1rem 0;
    z-index: 2;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 50%;
        height: 73px;
        background-color: #fafcff;
        border-top: $primary-border;
        right: 0;
        top: -3.5rem;
        transform: skewY(184deg);
        z-index: 1;
    }

    &::after {
        content: "";
        position: absolute;
        width: 50%;
        height: 73px;
        background-color: black;
        left: 0;
        top: -3.5rem;
        transform: skewY(176deg);
        background-color: #fafcff;
        border-top: $primary-border;
    }

    .container {
        display: flex;
        justify-content: center;
    }

    .logo {
        padding: 1.5rem 0;
        margin-right: 4rem;
        z-index: 2;
    }

    .info {
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem 0;

        .footer-links {
            &>div {
                display: flex;
                font-size: 1.4rem;

                &>div {
                    padding-right: 2rem; //border-right: 1px solid;
                    margin-right: 2rem;
                    position: relative;

                    a {
                        padding: 0.3rem 0;
                    }

                    &::before {
                        content: "";
                        height: 1rem;
                        width: 0.1rem;
                        background-color: #ccc;
                        position: absolute;
                        right: 0;
                        top: 0.6rem;
                    }
                }

                &:last-child {
                    &>div:last-child {
                        margin: 0;
                        border: 0;
                        padding: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        &>div {
            display: flex;
            padding-bottom: 1rem;
            font-size: 1.3rem;

            &:nth-child(n + 2) {
                align-items: center;
            }

            &.content-left {
                position: relative;
                flex-wrap: wrap;

                &>div {
                    display: flex;
                    align-items: center;
                    margin-right: 4rem;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    //height: 1px;
                    width: 100%;
                    background: linear-gradient(270deg,
                        rgba(248, 248, 248, 0) 0%,
                        #eeeeee 100%);
                }

                a {
                    align-items: center;

                    &:hover,
                    &:focus {
                        &::before {
                            display: none;
                        }
                    }
                }
                &.social{
                    div {
                        margin-right: 0.5rem;
                    }
                    a::before{
                        height: none
                    }
                }
            }
        }

        .icon-container {
            @include item-icon(3.5rem);
            margin-right: 1.6rem;
            display: inline-flex;
        }

        p {
            text-align: left;
            font-size: 1.2rem;
            color: #666;
            margin: 1.5rem 0 1rem;
        }
    }
}

html{
    &[lang=vi]{
        .footer-fax{
            display: none !important;
        }
    }
}

@media only screen and (max-width: 767px) {
    .footer {
        padding: 0;

        .container {
            flex-direction: column;
            padding: 0;

            .logo {
                margin: 0;
                padding: 0;
            }

            .info {
                padding-top: 4rem;

                .footer-links {
                    display: flex;
                    justify-content: center;
                    border-bottom: $primary-border;
                    padding: 0 2rem;
                    margin-bottom: 2rem;

                    &>div {
                        flex-direction: column;

                        &>div {
                            margin: 0;
                            padding: 0;
                            padding-bottom: 2.5rem;

                            &::before {
                                display: none;
                            }

                            a {
                                font-size: 1.6rem;
                            }
                        }

                        &:first-child {
                            padding-right: 13rem;
                        }
                    }
                }

                &>div {
                    padding: 1rem 2rem;
                    font-size: 1.4rem;

                    &.content-left {
                        justify-content: space-between !important;
                        border-bottom: $primary-border;

                        &::after {
                            display: none;
                        }

                        &>div {
                            padding-bottom: 2rem;
                            margin-right: 2rem;
                        }
                    }
                }

                p {
                    text-align: center;
                    margin-top: 2rem;
                }
            }
        }
    }

    [lang="vi"] {
        .footer {
            .container {
                .footer-links {
                    &>div {
                        &:first-child {
                            padding-right: 3rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {

    /*Extra small devices (tablet/phone, from 768 to 991px) ipad 768-1024*/
    .footer {
        padding: 0;

        .container {
            flex-direction: column;

            .logo {
                padding-top: 0;
                margin: 0;
            }

            .info {
                align-items: center;
            }
        }
    }

    html[lang="vi"] {
        .footer {
            .footer-links {
                flex-direction: column;

                &>div:first-child {
                    &>div:last-child {
                        margin: 0;
                        padding: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }

            .info {
                .footer-links {
                    &>div {
                        &>div {
                            min-width: 16rem;
                            text-align: center;
                            margin-bottom: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}