/*=============================================
=            COLOR            =
=============================================*/

$primary-text-color: #444444;
$primary-header-color: #2c2c2c;
$primary-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1);
$primary-box-shadow-inset: inset 0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.1);
$primary-border: 1px solid #eee;
$border-linear: linear-gradient(270deg, #FFFFFF 0%, #E3E3E3 51.39%, #FFFFFF 100%);
$brand-color-gradient: linear-gradient(311.56deg, #EE8768 0%, #EE8768 27.3%, #EAB65A 100%);
$background-color-lightblue: #F7FAFD;
/*=====  End of COLOR  ======*/