@import "../../../../styles/variables";
@import "../../../../styles/mixin";
.be-website-section {
  .container.common-content {
    // max-width: 77rem;
  }
  .row {
    [class*="col-"] {
      align-self: center;
      margin-top: 6rem;
    }
  }
  @media only screen and (max-width: 767px) {
    h3 {
      span {
        display: block;
        font-size: 2.2rem;
      }
      br {
        display: none;
      }
    }
    .order-sm-1,
    .order-sm-3,
    .order-sm-5 {
      padding: 0 !important;
    }
    .order-sm-2,
    .order-sm-4,
    .order-sm-6 {
      margin-top: 2.5rem !important;
    }
    .row {
      [class*="col-"] {
        text-align: center !important;
        br {
          display: block;
        }
      }
    }
  }
}

.be-booking-engine-section {
  z-index: 3;
  &::before,
  &::after {
    display: none;
  }
  .container {
    .common-content {
      max-width: 66rem;
      margin: auto;
      margin-bottom: 3rem;
    }
    [class*="col-"] {
      margin: 2.5rem 0;
    }
    .card-body {
      border: 0;
      align-items: center;
      .item-icon {
        // height: 7.5rem;
        // width: 7.5rem;
        // box-shadow: $primary-box-shadow-inset;
        // background-color: white;
        // border-radius: 50%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        @include item-icon(7.5rem);
        margin-bottom: 2rem;
      } // .p-content {
      //     text-align: left;
      //     ul {
      //         list-style: disc;
      //         margin: 0;
      //         li {
      //             line-height: 1.2;
      //             padding-bottom: 1rem;
      //         }
      //     }
      //     a {
      //         font-size: 1.5rem; // color: $primary-header-color;
      //         font-weight: 600;
      //         line-height: 1;
      //     }
      // }
    }
    .be-message {
      max-width: 55rem;
      margin: auto;
      img {
        margin: 2.8rem 0 5rem;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .row {
      [class*="col-"] {
        &:nth-child(-n + 3) {
          &:after {
            content: "";
            height: 0.1rem;
            display: block;
            background: linear-gradient(
              270deg,
              #ffffff 0%,
              #e3e3e3 51.39%,
              #ffffff 100%
            );
            margin: 1rem auto 0;
          }
        }
      }
    }
    .be-image {
      margin: 0 -1.5rem;
    }
    .be-message {
      margin: 0 -1.2rem !important;
      h6 {
        font-size: 1.6rem;
        line-height: 2.5rem;
      }
    }
  }
}

.be-ask-for-email-section {
  position: relative;
  padding-top: 29.5rem;
  padding-bottom: 15rem;
  margin-top: -22.5rem;
  background: url("/images/home/section-line.svg") center -1px no-repeat,
    linear-gradient(180deg, $background-color-lightblue 0px, #fff 55rem);
  background-size: auto, 100%;
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: url("/images/email-bg.svg") -418px 0 no-repeat;
    display: block !important;
    background-size: 878px;
    border: none;
    z-index: -1;
  }
  &::before {
    transform: rotateY(180deg);
  }
  &::after {
    transform: none;
  }
  @media only screen and (max-width: 767px) {
    @include mobile-contact-section;
    .section-content br {
      display: block;
    }
  }
  @media only screen and (max-width: 991.98px) and (min-width: 768px) {
    &::before,
    &::after {
      background-position: -527px 0;
    }
  }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {
  .be-website-section {
    .section-header {
      padding-left: 2rem;
      padding-right: 2rem;
      br {
        display: none;
      }
    }
  }
}
