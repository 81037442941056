@import "../../../styles/variables";

html {
    font-size: 10px;

    body {
        font-family: "Noto Sans", sans-serif;
        overflow-x: hidden;
        line-height: 1.7;
        color: $primary-text-color;
        font-weight: 500;

        &.disable-scroll {
            overflow: hidden;
        }
    }

    // &[lang='en'] {
    //     body {
    //         font-family: Gothic, sans-serif;
    //     }
    // }
}

.App {
    text-align: center;
    font-size: 1.4rem;

    .app-content {
        margin-top: 7.9rem;
        min-height: calc(100vh - 35rem);
    }
}

ul {
    list-style: none;
    padding: 0;
}

.hide {
    display: none;
}

.content-center {
    display: flex;
    justify-content: center !important;
}

.content-left {
    display: flex;
    justify-content: flex-start !important;

    @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
        &.header-line {

            max-width: 100%;
        }
    }

}

.content-right {
    display: flex;
    justify-content: flex-end !important;

    @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
        &.header-line {

            max-width: 100%;
        }
    }
}

.align-center {
    text-align: center !important;
}

.align-left {
    text-align: left !important;
}

.align-right {
    text-align: right !important;
}

.align-bottom {
    margin-top: auto;
}

section {
    padding-top: 5rem;
    padding-bottom: 9rem;
    position: relative;
    z-index: 2;

    &::before {
        content: "";
        position: absolute;
        width: 50%;
        height: 7.3rem;
        background-color: white;
        border: none;
        border-top: $primary-border;
        left: 0;
        bottom: -4rem;
        -webkit-transform: skewY(4deg);
        transform: skewY(184deg);
        z-index: 1;
    }

    &::after {
        content: "";
        position: absolute;
        width: 50%;
        height: 7.3rem;
        background-color: black;
        right: 0;
        bottom: -4rem;
        -webkit-transform: skewY(-4deg);
        transform: skewY(176deg);
        background-color: white;
        border: none;
        border-top: $primary-border;
    }

    &:last-child {

        &::before,
        &::after {
            display: none;
        }
    }

    // .end-section {
    //     display: flex;
    //     justify-content: space-between;
    //     margin-top: 5rem;
    //     &::before {
    //         content: "";
    //         display: flex;
    //         width: 50%;
    //         height: 1px;
    //         background-color: black;
    //         left: 0;
    //         bottom: 0;
    //         transform: skewY(4deg);
    //     }
    //     &::after {
    //         content: "";
    //         display: flex;
    //         width: 50%;
    //         height: 1px;
    //         background-color: black;
    //         right: 0;
    //         bottom: 0;
    //         transform: skewY(-4deg);
    //     }
    // }
    .section-header {
        //font-size: 2.8rem;
        padding-bottom: 2rem;
        line-height: 3.7rem;
    }

    .section-content {
        [class*="col-"] {
            margin-top: 2.5rem;
        }

        .card-body {
            padding: 1rem;
            height: 100%; // text-align: left;
            background: transparent;

            p {
                width: 100%;
            }
        }

        .p-title {
            font-weight: 500;
            margin-bottom: 1.2rem;
        }

        .blur-text {
            opacity: 0.5;
        }

        img {
            max-width: 100%;
        }
    }

    @media only screen and (max-width: 767px) {
        &::before {
            height: 5rem;
        }

        &::after {
            content: "";
            height: 5rem;
        }

        .section-header {
            padding: 0 2rem 2rem;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.3;
    font-weight: 400;
    margin-bottom: 2rem;
    color: $primary-header-color;
}

h1 {
    font-size: 4.7rem;
}

h2 {
    font-size: 3.7rem;
}

h3 {
    font-size: 3rem;
}

h4 {
    font-size: 2.7rem;
}

h5 {
    font-size: 2.2rem;
}

h6 {
    font-size: 2rem;
}

.header-line {
    max-width: 100%;
    // margin: auto;
    display: flex;
    justify-content: center;

    &:before {
        content: "";
        height: 0.2rem;
        width: 2rem;
        border-radius: 1px;
        background: $brand-color-gradient;
        box-shadow: $primary-box-shadow;
        margin-right: 0.1rem;
    }

    &:after {
        content: "";
        height: 0.2rem;
        max-width: 13rem;
        border-radius: 1px;
        background: $brand-color-gradient;
        box-shadow: $primary-box-shadow;
        flex-grow: 1;
    }

    &.sort-line {
        &:before {
            width: 1rem;
        }
    }

    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
        max-width: calc(100% - 13rem);
    }
}

p {
    margin-bottom: 1.2rem;
}

br.mobile {
    display: none !important;
}

.btn {
    font-size: 1.4rem;
    line-height: 1;
    padding: 1.1rem 1.8rem;
    border-radius: 1.5rem 0 1.5rem 1.5rem;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12) !important;
    border: none;
    outline: none;

    &:focus {
        outline: none;
    }

    span {
        padding-right: 1rem;
    }

    &.btn-primary {
        background: $brand-color-gradient;
        color: white;

        &:focus {
            outline: none;
        }
    }

    img {
        &:first-child {
            margin-right: 1.5rem;
        }

        &:last-child {
            margin-left: 1.5rem;
        }
    }
}

.quote {
    position: relative;
    padding: 0 2.7rem;
    display: -ms-inline-flexbox;

    &::before {
        content: url("/images/home/quote.svg");
        display: inline-block;
        margin-left: -2.7rem;
        position: absolute;
        top: -1rem; // left: -1.6rem;
        transform: rotateX(180deg);
    }

    &::after {
        content: url("/images/home/quote.svg");
        display: inline-block;
        margin-left: 1rem;
        transform: rotateY(180deg);
        position: absolute;
        bottom: -0.8rem; // right: -1.6rem;
    }
}

.lang-flag {
    //box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border: $primary-border;
    background-color: white;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    display: inline-flex;
    margin-right: 1.2rem;
    justify-content: center;
    align-items: center;

    img {
        height: 1.9rem;
        width: 1.9rem;
        border-radius: 50%;
    }
}

strong {
    font-weight: 700;
    color: $primary-header-color;
}

a:not(.btn) {
    color: #555;
    position: relative;

    &::before {
        background-color: #000;
        content: "";
        height: 1px;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        transform: translateY(1px);
        transition: all 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
        transition-property: opacity, transform;
        width: 100%; // margin-top: 3px;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: black;

        &::before {
            opacity: 1;
            transform: translateY(-3px) translateZ(0);
        }
    }
}

@media only screen and (max-width: 767px) {

    /* Small devices (phones, 600px and down) */
    body {
        .container {
            padding-left: 2rem;
            padding-right: 2rem;

            .row {
                margin-left: -2rem;
                margin-right: -2rem;

                [class*="col-"] {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }
        }

        h1 {
            font-size: 2.9rem;
        }

        h2 {
            font-size: 2.8rem;
        }

        h3 {
            font-size: 2.4rem;
        }

        h4 {
            font-size: 2.2rem;
        }

        h5 {
            font-size: 2.2rem;
        }

        h6 {
            font-size: 1.8rem;
        }

        .App {
            .app-content {
                margin-top: 6.1rem;
            }
        }

        .section-content,
        .common-content {
            br {
                display: none;
            }

            .card-body {
                padding-top: 0;

            }
        }

        br.mobile {
            display: block;
        }
    }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {
    body {

        .section-content,
        .common-content {
            br {
                display: none;
            }
        }

        // br.mobile {
        //     display: block;
        // }
    }
}