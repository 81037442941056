@import "../../../styles/variables";

.App-header {
    background-color: white;
    position: fixed;
    top: 0;
    width: 100vw;
    border-bottom: $primary-border; // box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    z-index: 5;

    .navbar {
        padding: 0 1.5rem;
        color: $primary-text-color;

        .navbar-brand {
            padding: 0.55rem 0;

            &:hover,
            &:focus {
                &::before {
                    display: none;
                }
            }
        }

        ul {
            li.nav-item {
                margin: 0;
                padding: 2.55rem 1.8rem; // &:hover {

                //     .dropdown-menu {
                //         display: block;
                //     }
                // }
                .header-line {
                    display: none;
                    max-width: 100%;

                    &::after {
                        max-width: 100%;
                    }
                }

                &>a.nav-link {
                    padding: 0.2rem 0;
                    text-transform: uppercase;
                    color: #555;
                    font-size: 1.4rem;
                    cursor: pointer;

                    &::after {
                        margin-left: 0.5rem;
                        border-top-color: #666;
                    }

                    &:hover,
                    &:focus {
                        color: black;
                        text-decoration: none;

                        &::before {
                            display: none;
                        }
                    }
                }

                &.active {
                    &>a.nav-link {
                        font-weight: bold;
                    }

                    .header-line {
                        display: flex;
                    }
                }

                .dropdown-menu {
                    color: #555;
                    font-size: 1.4rem;
                    cursor: pointer;
                    width: 25.4rem;
                    transform: translateX(50%);
                    margin-right: 50%;
                    padding: 2rem 0;
                    box-shadow: 0 -1px 9px 0 rgba(0, 0, 0, 0.2);
                    background-color: rgba(255, 255, 255, 0.94);
                    border-radius: 7px;
                    border: none; //top: 5.3rem;
                    margin-top: -0.5px;

                    &::before {
                        position: absolute;
                        display: block;
                        content: "";
                        border-color: transparent;
                        border-style: solid;
                        top: -0.8rem;
                        left: calc(50% - 0.8rem);
                        border-color: transparent transparent rgba(255, 255, 255, 0.94) rgba(255, 255, 255, 0.94);
                        border-width: 0.8rem;
                        box-shadow: -3px 3px 6px 0 rgba(0, 0, 0, 0.1);
                        transform: rotate(135deg);
                    }

                    .nav-link,
                    &>div {
                        padding: 0.8rem 2.8rem;
                        color: #555;
                        font-size: 1.4rem;
                        display: flex;
                        align-items: center;

                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            left: 0;
                            width: 0;
                            height: 2.3rem;
                            background: $brand-color-gradient;
                            top: 0.8rem;
                            transition: 0.15s width 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
                            transform: none;
                            box-shadow: $primary-box-shadow;
                        }

                        &:hover {
                            color: black;
                            text-decoration: none;

                            &::before {
                                display: none;
                            }

                            &::after {
                                width: 0.3rem;
                            }
                        }

                        &:focus {
                            &::before {
                                display: none;
                            }
                        }
                    }

                    .dropdown-item {

                        &:hover,
                        &.active,
                        &:active {
                            background-color: transparent;
                            outline: none;
                        }
                    }

                    button {
                        padding: 0;
                    }
                }

                &.languages {
                    padding: 2.3rem 0 2.4rem 3.6rem;
                    position: relative;
                    display: flex;

                    &:before {
                        content: "";
                        height: 1rem;
                        width: 0.1rem;
                        background-color: #ccc;
                        position: absolute;
                        left: 0;
                        top: 3.5rem;
                    }

                    .dropdown-menu {
                        width: 17rem;
                        font-family: "Noto Sans", sans-serif;

                        &>div {
                            position: relative;
                        }
                    }

                    .nav-link {
                        display: inline-flex;
                        align-items: center;
                        padding: 0;
                    }
                }
            }
        }

        .navbar-collapse {
            .close-collapse-nav {
                display: none;
            }
        }
    }
}

@media (min-width: 992px) {
    .App-header {
        padding-right: 1.7rem;
    }
}

@media only screen and (min-width: 768px) {
    html:not([lang="vi"]) {
        .App-header {
            .collapsing {
                height: auto !important;
            }
        }
    }
}

@media only screen and (max-width: 767px) {

    /* Small devices (phones, 600px and down) */
    .App-header {
        .navbar {
            &.container {
                padding: 0 2rem;
            }

            .navbar-brand {
                img {
                    height: 3rem !important;
                }
            }

            .navbar-toggler {
                border: none;
                padding: 2.9rem 0 2.9rem 1.5rem;
                cursor: pointer;
                background-color: white;
                border-radius: 0;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                .navbar-toggler-icon {
                    background-image: none;
                    height: 0;
                    border-bottom: 0.2rem solid #4e4e4e;
                    display: block;
                    margin-left: auto;
                    border-radius: 0.1rem;
                    width: 1.3rem;
                    position: relative;

                    &::before,
                    &::after {
                        position: absolute;
                        content: "";
                        height: 0;
                        border-bottom: 0.2rem solid #4e4e4e;
                        display: block;
                        border-radius: 0.1rem;
                        right: 0;
                    }

                    &::after {
                        width: 1.9rem;
                        margin-bottom: 0;
                        bottom: -0.8rem;
                    }

                    &::before {
                        top: -0.6rem;
                        width: 1.6rem;
                    }
                }
            }

            .navbar-collapse {
                background-color: rgba(255, 255, 255, 0.98);
                transition: height 0.35s ease;
                position: fixed;
                max-height: 100vh;
                height: 0;
                width: 100vw;
                top: 0;
                left: 0;

                .close-collapse-nav {
                    display: flex;
                    justify-content: flex-end;

                    span {
                        padding: 1.6rem 3rem;
                    }
                }

                .navbar-nav {
                    height: calc(100vh - 6.1rem); // width: 25rem;
                    margin: auto;
                    padding-top: 3rem;
                    overflow: auto;
                }

                &.show {
                    height: 100vh;
                }

                &.show,
                &.collapsing {
                    .navbar-nav {
                        .nav-item {
                            // border-bottom: 1px solid rgba(204, 204, 204, 0.42);
                            transition: max-height 0.3s ease-out;
                            transition-delay: 0.3s;
                            max-height: 9.5rem;
                            padding-bottom: 0;

                            &::after {
                                content: "";
                                height: 1px;
                                width: 100%;
                                background: linear-gradient(270deg,
                                        #ffffff 0%,
                                        #e3e3e3 51.39%,
                                        #ffffff 100%);
                                margin-top: 2.55rem;
                                display: block;
                            }

                            &:not(.languages) {
                                &>a {
                                    &::after {
                                        border: none;
                                        background-image: url("/images/icons/chevron.svg");
                                        height: 2.2rem;
                                        width: 1.2rem;
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                        transform: rotate(90deg);
                                        vertical-align: middle;
                                        margin: 0;
                                        margin-left: 1.5rem;
                                        transition: transform 0.3s ease-in-out;
                                    }

                                    &[aria-expanded="true"] {
                                        &::after {
                                            transform: rotate(-90deg);
                                        }
                                    }
                                }
                            }

                            &>a {
                                font-size: 2.2rem;
                                text-transform: unset;
                            }

                            .dropdown-menu {
                                background: transparent;
                                box-shadow: none;
                                transform: none;
                                margin: 0;
                                width: 100%;
                                padding: 0;
                                display: block !important;
                                max-height: 0;
                                opacity: 0;
                                transition-delay: 0.3s;
                                transition: max-height 0.3s linear, opacity 0.3s linear;

                                &::before {
                                    display: none;
                                }

                                .nav-link {
                                    justify-content: center;
                                }
                            }

                            &.dropdown.show {
                                max-height: 100vh;
                                transition: max-height 0.3s ease-in;

                                .dropdown-menu {
                                    &.show {
                                        max-height: 100vh;
                                        opacity: 1; //transition: max-height 1s linear, opacity 1s linear;
                                    }
                                }
                            }

                            &.languages {
                                width: 25rem;
                                margin: 0 auto;
                                padding: 2.55rem 1.8rem;
                                border-bottom: none;
                                flex-direction: column;
                                align-items: center;

                                &::after,
                                &::before {
                                    display: none !important;
                                }

                                .dropdown-menu {
                                    &>div {
                                        justify-content: left;
                                        margin-left: 8%;
                                    }
                                }
                            }

                            &.active {
                                a {
                                    font-weight: 500;
                                }
                            }

                            .header-line {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {

    /*Extra small devices (tablet/phone, from 768 to 991px) ipad 768-1024*/
    .App-header {
        .navbar {
            .nav-item {
                padding: 3.1rem 1.8rem 2rem !important;

                &.languages {
                    padding: 3.2rem 0 1.9rem 1.8rem !important;

                    &::before {
                        top: 4rem !important;
                    }
                }
            }
        }
    }

    html[lang="vi"] {
        .App-header {
            .navbar {
                justify-content: space-between;

                .navbar-toggler {
                    display: block;
                }

                .navbar-collapse {
                    &:not(.show) {
                        display: none !important;
                    }

                    &.show,
                    &.collapsing {
                        display: block !important;
                    }
                }

                // &.container {
                //     padding: 0 2rem;
                // }
                // .navbar-brand {
                //     img {
                //         height: 3rem !important;
                //     }
                // }
                .navbar-toggler {
                    border: none;
                    padding: 2.9rem 0 2.9rem 1.5rem;
                    cursor: pointer;
                    background-color: white;
                    border-radius: 0;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }

                    .navbar-toggler-icon {
                        background-image: none;
                        height: 0;
                        border-bottom: 0.2rem solid #4e4e4e;
                        display: block;
                        margin-left: auto;
                        border-radius: 0.1rem;
                        width: 1.3rem;
                        position: relative;

                        &::before,
                        &::after {
                            position: absolute;
                            content: "";
                            height: 0;
                            border-bottom: 0.2rem solid #4e4e4e;
                            display: block;
                            border-radius: 0.1rem;
                            right: 0;
                        }

                        &::after {
                            width: 1.9rem;
                            margin-bottom: 0;
                            bottom: -0.8rem;
                        }

                        &::before {
                            top: -0.6rem;
                            width: 1.6rem;
                        }
                    }
                }

                .navbar-collapse {
                    background-color: rgba(255, 255, 255, 0.98);
                    transition: height 0.35s ease;
                    position: fixed;
                    max-height: 100vh;
                    height: 0;
                    width: 100vw;
                    top: 0;
                    left: 0;

                    .close-collapse-nav {
                        display: flex;
                        justify-content: flex-end;

                        span {
                            padding: 1.6rem 3rem;
                        }
                    }

                    .navbar-nav {
                        height: calc(100vh - 6.1rem); // width: 25rem;
                        margin: auto;
                        padding-top: 3rem;
                        overflow: auto;
                        flex-direction: column;
                    }

                    &.show {
                        height: 100vh;
                    }

                    &.show,
                    &.collapsing {
                        .navbar-nav {
                            .nav-item {
                                // border-bottom: 1px solid rgba(204, 204, 204, 0.42);
                                transition: max-height 0.3s ease-out;
                                transition-delay: 0.3s;
                                max-height: 9.5rem;
                                padding-bottom: 0;

                                &::after {
                                    content: "";
                                    height: 1px;
                                    width: 100%;
                                    background: linear-gradient(270deg,
                                            #ffffff 0%,
                                            #e3e3e3 51.39%,
                                            #ffffff 100%);
                                    margin-top: 2.55rem;
                                    display: block;
                                }

                                &:not(.languages) {
                                    &>a {
                                        &::after {
                                            border: none;
                                            background-image: url("/images/icons/chevron.svg");
                                            height: 2.2rem;
                                            width: 1.2rem;
                                            background-repeat: no-repeat;
                                            background-size: contain;
                                            transform: rotate(90deg);
                                            vertical-align: middle;
                                            margin: 0;
                                            margin-left: 1.5rem;
                                            transition: transform 0.3s ease-in-out;
                                        }

                                        &[aria-expanded="true"] {
                                            &::after {
                                                transform: rotate(-90deg);
                                            }
                                        }
                                    }
                                }

                                &>a {
                                    font-size: 2.2rem;
                                    text-transform: unset;
                                }

                                .dropdown-menu {
                                    position: static;
                                    top: 0;
                                    background: transparent;
                                    box-shadow: none;
                                    transform: none;
                                    margin: 0;
                                    width: 100%;
                                    padding: 0;
                                    display: block !important;
                                    max-height: 0;
                                    opacity: 0;
                                    transition-delay: 0.3s;
                                    transition: max-height 0.3s linear, opacity 0.3s linear;

                                    &::before {
                                        display: none;
                                    }

                                    .nav-link {
                                        justify-content: center;
                                    }
                                }

                                &.dropdown.show {
                                    max-height: 100vh;
                                    transition: max-height 0.3s ease-in;

                                    .dropdown-menu {
                                        &.show {
                                            max-height: 100vh;
                                            opacity: 1; //transition: max-height 1s linear, opacity 1s linear;
                                        }
                                    }
                                }

                                &.languages {
                                    padding: 2.55rem 1.8rem 2.55rem 0;
                                    border-bottom: none;
                                    width: 25rem;
                                    margin: 0 auto !important;

                                    &::after,
                                    &::before {
                                        display: none;
                                    }

                                    .dropdown-menu {
                                        &>div {
                                            justify-content: left;
                                            margin-left: 4%;
                                        }
                                    }
                                }

                                &.active {
                                    a {
                                        font-weight: 500;
                                    }
                                }

                                .header-line {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .app-content {
            margin-top: 6rem;
        }

        .sub-menu {
            top: 5.9rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    [lang="vi"] {
        .App-header {
            .navbar {
                .dropdown-menu {
                    &>div {
                        margin-left: 0 !important;
                    }
                }
            }
        }
    }

    [lang="ja"],
    [lang="ko"] {
        .App-header {
            .navbar {
                .dropdown-menu {
                    &>div {
                        margin-left: 8% !important;
                    }
                }
            }
        }
    }
}