@import "../../../../styles/variables";
@import "../../../../styles/mixin";

.choose-accom-section {
    padding-bottom: 6rem;

    .row {
        [class*="col-"] {
            padding: 0 0.9rem;
        }

        .col-md-4 {
            &:first-child>div {
                &::before {
                    content: "";
                    display: block;
                    background: url(/images/business/OTA/easy-top.svg) 0 0 no-repeat;
                    height: 4rem;
                    width: 100%;
                    background-size: 100%;
                }

                &::after {
                    //content: '';
                    background: url(/images/business/OTA/easy-top.svg) 0 0 no-repeat;
                }

                @media only screen and (min-width: 768px) and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                    picture {
                        max-height: 397px;
                        flex-grow: 1;
                    }
                }

            }

            &:nth-child(2)>div {
                &::before {
                    content: "";
                    display: block;
                    background: url(/images/business/OTA/cheap-top.svg) 0 0 no-repeat;
                    height: 4rem;
                    width: 100%;
                    background-size: 100%;
                }

                img {
                    margin-bottom: -1.6rem;
                }

                @media only screen and (min-width: 768px) and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                    picture {
                        max-height: 397px;
                        flex-grow: 4;
                    }
                }

            }

            &:nth-child(3)>div {
                &::before {
                    content: "";
                    display: block;
                    background: url(/images/business/OTA/done-top.svg) 0 0 no-repeat;
                    height: 4rem;
                    width: 100%;
                    background-size: 100%;
                }

                &::after {
                    //content: url(/images/business/OTA/easy.svg)
                }

                @media only screen and (min-width: 768px) and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                    picture {
                        max-height: 397px;
                        flex-grow: 4;
                    }
                }
            }

            &>div {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .p-content {
                    background: url("/images/business/OTA/loop.svg") repeat-y;
                    background-size: contain;
                    flex-grow: 1;
                    padding: 0 2.5rem;
                }

                img {
                    // margin-top: 3rem;
                }
            }
        }
    }

    h5 {
        img {
            display: block;
            margin: 0 auto;
        }
    }

    @media only screen and (max-width: 767px) {
        [class*="col-"] {
            padding: 0 !important;
        }

        .col-md-4 {
            &>div {

                &:before,
                .p-content {
                    background: none !important;
                }

                &::before {
                    height: 1.5rem !important;
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        .col-md-4 {

            &:first-child>div,
            &:nth-child(2)>div,
            &:nth-child(3)>div {
                &::before {
                    height: 3.3rem !important;
                }
            }
        }
    }
}

.ota-lowercost-section {
    z-index: 3;

    &::before,
    &::after {
        display: none;
    }

    img {
        margin-top: 4rem;
    }

    p {
        margin: 0 auto;
        max-width: 81rem;
    }

    .p-title {
        margin-top: 5rem;
        margin-bottom: 1rem;
    }

    // ul {
    //     list-style-type: disc;
    //     list-style-position: inside;
    //     li {
    //         padding: 0.3rem 0 0.2rem 2rem;
    //     }
    // }
    ul {
        list-style: disc;
        padding-left: 3.5rem;

        li {
            padding: 0.3rem 0 0.2rem 0.5rem;

            ul {
                list-style: none;
                padding-left: 2rem;
            }
        }
    }

    @media only screen and (max-width: 767px) {

        .order-1,
        .order-3 {
            padding: 0 !important;
        }

        .order-2,
        .order-4 {
            &>div {
                &:first-child {
                    h6 {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.ota-partner-section {
    position: relative;
    padding-top: 26.5rem;
    padding-bottom: 12rem;
    margin-top: -22.5rem;
    background: url("/images/home/section-line.svg") center -1px no-repeat,
        linear-gradient(180deg, $background-color-lightblue 0px, #fff 40rem);
    background-size: auto, 100%;

    &::before,
    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: url("/images/email-bg.svg") -418px 85px no-repeat;
        display: block !important;
        background-size: 878px;
        border: none;
        z-index: -1;
    }

    &::before {
        transform: rotateY(180deg);
    }

    &::after {
        transform: none;
    }

    .container {
        &>.row {
            &>div:first-child {
                .section-content {
                    display: flex;
                    align-items: flex-end;

                    &>img {
                        margin-right: 2.5rem;
                    }
                }
            }
        }
    }

    .section-content {
        align-items: center;

        .item-icon {
            @include item-icon(7.5rem);
            margin-right: 2.2rem;
        }

        h6 {
            margin-top: 4rem;
        }

        div.p-title {
            margin-bottom: 0;
            font-size: 1.8rem;
            color: $primary-header-color;
            line-height: 1;
        }

        h5 {
            margin-bottom: 1.5rem;

            img {
                margin-left: 1rem;
                margin-bottom: 0.5rem;
            }

            a {
                color: $primary-header-color;
                font-size: 2.4rem;
                display: flex;
                align-items: flex-end;

                &::before {
                    display: none;
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        @include mobile-contact-section;

        .row {
            &>div {
                &:first-child {
                    &>div {
                        display: block !important;
                        margin: 0 !important;

                        &:after {
                            content: "";
                            height: 0.1rem;
                            background: linear-gradient(270deg,
                                #ffffff 0%,
                                #e3e3e3 51.39%,
                                #ffffff 100%);
                            display: block;
                            margin: 4rem auto;
                        }

                        &>img {
                            margin-bottom: 2.5rem;
                            margin-right: 0 !important;
                        }

                        &>div {
                            text-align: center !important;

                            .header-line {
                                display: none;
                            }
                        }
                    }
                }

                &:last-child {
                    h3 {
                        text-align: center;
                    }

                    .header-line {
                        justify-content: center !important;
                    }

                    .section-content {
                        text-align: center;
                    }
                }
            }
        }

        br {
            display: block !important;
        }
    }

    @media only screen and (max-width: 991.98px) and (min-width: 768px) {

        &::before,
        &::after {
            background-position: -527px 85px;
        }

        .row {
            &>div {
                &:first-child {
                    &>div {
                        display: block !important;
                        margin: 0 !important;

                        &>img {
                            margin-bottom: 2.5rem;
                            margin-right: 0 !important;
                        }

                        &>div {
                            text-align: center !important;

                            .header-line {
                                justify-content: center !important;
                            }
                        }
                    }
                }

                &:last-child {
                    h3 {
                        text-align: center;
                    }

                    .header-line {
                        justify-content: center !important;
                    }

                    .section-content {
                        text-align: center;
                    }
                }
            }
        }
    }

    .hotel-partner{
        img{
            margin: 1rem;          
        }
    }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {
    html[lang="vi"] {
        .ota-partner-section {
            .row {
                .col-md-6 {
                    &:first-child {
                        max-width: 40%;
                        flex: 0 0 40%;
                    }

                    &:last-child {
                        max-width: 60%;
                        flex: 0 0 60%;

                        p {
                            br {
                                display: block !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .ota-partner-section {
        .row {
            .col-md-6 {
                &:last-child {
                    p {
                        br {
                            display: block !important;
                        }
                    }
                }
            }
        }
    }
}