@import "../../../styles/variables";

.sub-menu {
    border-bottom: 1px solid white;
    position: sticky;
    top: 7.8rem;
    z-index: 4;

    &.for-ie11 {
        position: relative;
        top: 0;
        width: 100%;
    }

    ul {
        justify-content: center;
        padding: 1.5rem 0 1.4rem;
        background-color: $background-color-lightblue;
        line-height: 1;
        flex-wrap: nowrap;
        overflow-x: auto;
        border-bottom: 1px solid #eee;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            padding: 0 3rem;
            white-space: nowrap;

            .header-line {
                display: none;
                max-width: 100%;

                &::after {
                    max-width: 100%;
                }
            }

            a {
                color: #555;
                padding: 0.5rem 0;

                &:hover,
                &:focus {
                    color: black;

                    &::before {
                        display: none;
                    }
                }
            }

            &.active {
                font-weight: bold;

                .header-line {
                    display: flex;
                }
            }
        }
    }

    &+div section:first-child {
        // background: linear-gradient(180deg, $background-color-lightblue 0px, #fff 28rem);
        background: $background-color-lightblue;
        background: -moz-linear-gradient(top, $background-color-lightblue 0%, #ffffff 100%);
        background: -webkit-linear-gradient(top, $background-color-lightblue 0%, #ffffff 100%);
        background: linear-gradient(to bottom, $background-color-lightblue 0%, #ffffff 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$background-color-lightblue', endColorstr='#ffffff', GradientType=0);
        background-size: 100% 28rem;
        background-repeat: no-repeat;
    }

    @media only screen and (max-width: 767px) {
        top: 6rem;

        ul {
            justify-content: flex-start;

            li {
                padding: 0 1.5rem;

                &:first-child {
                    padding-left: 2rem;
                }

                &:last-child {
                    padding-right: 2rem;
                }
            }
        }

        &:before,
        &:after {
            content: "";
            width: 2rem;
            min-width: 2rem;
            position: absolute;
            padding: 0;
            display: block;
            top: 0;
            bottom: .1rem;
            z-index: 100;
        }

        &:before {
            background: -moz-linear-gradient(left, rgba(247, 251, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            background: -webkit-linear-gradient(left, rgba(247, 251, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            background: linear-gradient(to right, rgba(247, 251, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7fbff', endColorstr='#00ffffff', GradientType=1);
            left: 0;
        }

        &:after {
            background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(247, 251, 255, 1) 100%);
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(247, 251, 255, 1) 100%);
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(247, 251, 255, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#f7fbff', GradientType=1);
            right: 0;
        }
    }
}