.ceo-section {
    .section-header {
        padding-bottom: 2.6rem;
    }

    .section-content {
        max-width: 81.1rem;
        margin: auto;
        display: flex;



        h4.quote {
            margin: 4rem 0 6rem; // padding: 0;
            // &::before {
            //     margin-left: -2.7rem;
            // }
            // &::after {
            //     margin-left: 1rem;
            // }
        }

        p {
            margin-bottom: 2rem;
        }

        & > div:first-child {
            margin-right: 5.6rem;

            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                width: 60.9rem;
            }

            & > div {
                &:last-child {
                    margin-top: 6.6rem;
                }
            }
        }

        .ceo-img {
            min-width: 21.2rem;
            max-width: 21.2rem;
            margin: 0 auto;
            position: relative;

            img {

                mix-blend-mode: multiply;

                @media screen and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                    width: 20.2rem;
                }
            }
        }

        @media only screen and (max-width: 767px) {
            flex-direction: column-reverse;
            padding-top: 2.5rem;

            & > div {
                &:first-child {
                    margin-right: 0;
                }
            }

            h4.quote {
                text-align: center;
                font-size: 2.4rem;
            }

            .ceo-img {
                text-align: center;

                img {
                    max-width: 25.2rem;
                }
            }
        }
    }
}
