.privacy-banner {
    padding: 0;
    height: 21rem;
    background: url("/images/logo/orange.svg") 10% -5% no-repeat, url("/images/logo/orange.svg") 90% 115% no-repeat, url("/images/about/about-banner.jpg") center; //background-position: center;
    background-size: 374px auto, 523px auto, 110%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);

    * {
        color: white;
    }

    // h2 {
    //     margin-top: 5rem;
    // }
    .react-parallax {
        height: 20rem;

        .parallax-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: white;
        }
    }

    @media only screen and (max-width: 767px) {
        background-position: -10rem -34%, calc(100% + 18rem) 165%, center 0% !important;
        background-size: 281px auto, 310px auto, 100% auto;
    }

    @media only screen and (max-width: 991.98px) and (min-width: 768px) {
        background-position: -28% -5%, 185% 115%, center !important;
    }
}

.privacy-content-section {
    h6 {
        margin-bottom: 0.6rem;
    }

    p {
        margin-bottom: 3rem;
    }

    li {
        padding-left: 2rem;
    }

    .contact {
        padding-left: 2.8rem;
    }

    @media only screen and (max-width: 767px) {
        .p-title {
            font-size: 2rem;
        }

        .contact {
            padding-left: 0;
        }

        br {
            display: block !important;
        }
    }

    @media only screen and (max-width: 991.98px) and (min-width: 768px) {
        br {
            display: block !important;
        }
    }
}