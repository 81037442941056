@import "../../../styles/variables";
@import "../../../styles/mixin";

.business-banner {
    padding: 0 2rem;
    height: 21rem;
    background: url("/images/logo/orange.svg") 10% -5% no-repeat,
        url("/images/logo/orange.svg") 90% 115% no-repeat,
        url("/images/business/business-banner.jpg") calc(50% + 10px) 28%; //background-position: center;
    background-size: 374px auto, 523px auto, 1500px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    transition: background-position 0.5s ease-out;

    * {
        color: white;
    }

    &::after,
    &::before {
        background-color: $background-color-lightblue;
    }

    // .react-parallax {
    //     height: 20rem;
    //     .parallax-content {
    //         height: 100%;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         color: white;
    //     }
    // }
    h2 {
        margin-top: 4rem;
    }

    @media only screen and (max-width: 767px) {
        background-position: -10rem -34%, calc(100% + 18rem) 165%, center 9% !important;
        background-size: 281px auto, 310px auto, 767px auto;

        br {
            display: none;
        }
    }

    @media only screen and (max-width: 991.98px) and (min-width: 768px) {
        background-position: -28% -5%, 185% 115%, calc(50% + 10px) 28% !important;
    }
}