.CI-page {
    .full-width-image {
        width: 100vw;
        height: 24rem;
        background-color: wheat;
        margin: 3.5rem 0 4rem;
    }

    .color-item {
        padding: 1.5rem 0.9rem 2rem;
        width: 18rem;
        .orange-gradient {
            color:#EE7E28;
        }
        .green-gradient {
            color: #07834C;
        }

        .color-cycle {
            height: 10rem;
            width: 10rem;
            border-radius: 50%;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
            border: 1rem solid white;
            margin: 0 auto 1.6rem;
           

            &.orange-gradient {
                background: linear-gradient(90deg, #BD1819 0%, #F1C400 100%);
            }
           

            &.green-gradient {
                background: linear-gradient(90deg, #07834C 0%, #1DA136 100%);
            }
           
            &.green {
                // background-color: #00953A;
                background-color: rgb(00, 149, 58);
            }

            &.orange {
                // background-color: #FF7E2E;
                background-color: #ff6000;
            }

            &.black {
                // background-color: #FF7E2E;
                background-color: #383838;
            }

            &.white {
                // background-color: #FF7E2E;
                background-color: #fffcf8;
            }
        }
    }

    .ci-intro-section {
        .section-content .container {
            // max-width: 76rem;
            margin: auto;
            margin-top: 1rem;
        }

        p {
            margin-top: 4rem;
        }

        .btn {
            margin: 0 1.3rem;
            display: flex;
            align-items: center;

            img {
                margin-right: 1rem;
            }
        }

        .full-width-image {
            background: url('#') center no-repeat, url('/images/about/ci-intro-bg.svg') top center repeat;
            background-size: 606px, auto;
            height: 25.7rem;
            width: 100%; // border: 1px solid #eee;

            img {
                &.left {
                    height: 25.7rem;
                }

                &.right {
                    height: 7rem;
                }
            }
        }
    }

    .color-system-section {
        .row {
            max-width: 72rem;
            margin: auto;
        }

        .title-comp {
            text-align: left;
            padding: 1.5rem 0.9rem 2rem;
            color: #ABACAE;
            position: relative;

            &::after {
                content: "";
                width: 13%;
                height: 2px;
                background: #ABACAE;
                position: absolute;
                bottom: 10px;
                left: 10px;
            }

            &::before {
                content: "";
                width: 13%;
                height: 2px;
                background: #ABACAE;
                position: absolute;
                top: 8px;
                left: 10px;
            }
        }
    }

    .color-system-section,
    .corporate-signature-section {
        .section-content .container {
            //max-width: 88rem;
            margin: auto;

            .content-center {
                flex-wrap: wrap;
            }
        }

        .color-item-signature {
            padding: 0;
            div {
                background-color: #fffcf8;
                border: 1px solid #C2C2C0;
                padding: 2rem;

                &.white {
                    background-color: #FFFFFF;
                }

                &.orange {
                    background-color: #ff6000;
                }
            }

        }
    }

    .corporate-signature-section {
        .section-content .container {
            margin-bottom: 5rem;
        }

        .full-width-image {
            margin-top: 0;
            margin-bottom: 1rem;
            height: 15rem;
            width: 100%;
            background-size: 478px, auto !important;

            &.img-1 {
                background: url('/images/about/ci-gray-logo.svg') center no-repeat, url('/images/about/ci-gray-bg.svg') top center repeat, ;
            }

            &.img-2 {
                background: url('/images/about/ci-orange-logo.svg') center no-repeat, url('/images/about/ci-orange-bg.svg') top center repeat, ;
            }

            &.img-3 {
                background: url('/images/about/ci-blue-logo.svg') center no-repeat, url('/images/about/ci-blue-bg.svg') top center repeat, ;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .CI-page {
        .ci-intro-section {
            .full-width-image {
                height: 14.8rem;
                background-size: 90% 75%, auto;

                img {
                    &.left {
                        height: 14.8rem;
                    }

                    &.right {
                        height: 4rem;
                    }
                }
            }

            .p-title {
                br {
                    display: inline-block;
                }
            }

            .btn {
                flex-direction: column;

                img {
                    margin-right: 0;
                    margin-bottom: .5rem;
                }
            }
        }

        .corporate-signature-section {
            .full-width-image {
                height: 11.5rem;
                background-size: 90% 75%, auto !important;
            }
        }

        .color-system-section {
            .row {
                max-width: 36rem;
                margin: auto;
            }
        }
    }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {

    /*Extra small devices (tablet/phone, from 768 to 991px) ipad 768-1024*/
    .CI-page {
        .section-content {
            p br {
                display: none;
            }
        }

        .ci-intro-section {
            .p-title {
                br {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (max-width: 382px) {
    .CI-page {
        .ci-intro-section {
            .full-width-image {
                height: calc(100%);

                img {
                    &.left {
                        height: calc(100% - 1rem);
                    }

                    &.right {
                        height: calc(100% - 10rem);
                    }
                }
            }
        }
    }
}