@import '../../../../styles/variables';
@import '../../../../styles/mixin';
.location-section {
   
    .container {
        padding: 5rem 2rem;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: auto auto auto;
        grid-template-columns: auto auto auto;
        justify-content: center;
        text-align: left;
        position: relative;
        max-width: 100rem;
        max-width: unset;
        .lang-flag{
            margin: 0.5rem 1rem 0 0;
        }
        h6 {
            // margin-bottom: 0.8rem;
            margin: 0.7rem 5rem 0 0;
            font-weight: bold;
            font-size: 1.5rem;
            img{
                display: none;
            }
        }
        p {
            margin-bottom: 0;
            vertical-align: middle;
            display: inline-flex;
            align-items: center;
        }
        &>div>div {
            display: flex;
            &>div {
                display: inline-flex;
                align-items: center;
                white-space: nowrap;
                margin: 0.3rem 5rem 0 0;
                min-width: 14.5rem;
               
                span{
                    margin: 0 0.2rem;
                }
                .icon-container {
                    &.inline-icon {
                        display: inline-flex;
                        margin-right: 2rem;
                    }
                }
            }
        }
        .line {
            height: 1px;
            background: linear-gradient(270deg, #FFFFFF 0%, #E3E3E3 51.39%, #FFFFFF 100%);
            grid-column-start: 1;
            grid-column-end: 4;
            margin: 5rem 0;
            &:first-child{
                display: none;
            }
        }
        &:last-child{
            &::after{
                display: none;
            }
        }
        .icon-container{
            display: none;
        }
        
    }
}

@media only screen and (max-width: 767px) {
    .location-section {
        .container {
            padding-top: 2.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .lang-flag{
                margin: 0;
                height: 3.5rem;
                width: 3.5rem;
                img{
                    height: 2.9rem;
                    width: 2.9rem;
                }
            }
            .icon-container {
                @include item-icon(3.5rem);
                margin-right: 0;
                display: block;
                &.inline-icon {
                    display: inline-flex;
                    margin: 0;
                }
            }
            h6{
                margin: .8rem 0 0.6rem;
                display: flex;
                align-items: center;
            }
            p{
                text-align: center;
                margin: 0;
                line-height: 2rem;
                img{
                    display: none;
                }
            }
            .line{
                width: 100%;
               
                &[data-branch="ja"]~[data-branch="ja"]{
                    margin: 2rem 0;
                    background: none;
                    &+.lang-flag{
                        display: none !important;
                    }
                }
            }
            &>div>div {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                &>div {
                    width: 50%;
                    margin-top: 1.3rem;
                    margin-right: 0;
                    min-width: 14.2rem;
                    display: grid;
                    grid-template-columns: auto auto;
                    justify-content: center;
                    white-space: normal;
                    line-height: 1;
                    span{
                        //display: none;
                        display: block;
                        height: 0;
                        max-height: 0;
                        width: 0;
                        max-width: 0;
                        visibility: hidden;
                        &::before{
                            content: "\A";
                            white-space: pre;
                        }
                    }
                }
            }
        }
    }
}