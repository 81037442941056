@import "../../../../styles/variables";
.job-opportunities-section {
    background: linear-gradient(180deg, $background-color-lightblue 0px, #fff 28rem);
    padding-bottom: 6.5rem;
    .section-content {
        max-width: 65rem;
        .job-list {
            padding: 10rem 0;
            .job-item {
                display: flex;
                padding: 2rem 0 3rem;
                margin-bottom: 1.5rem;
                &>div {
                    padding-left: 3rem;
                    a {
                        padding-bottom: 0.3rem;
                    }
                    &:first-child {
                        padding: 0;
                        span {
                            border: $primary-border;
                            border-radius: 1.9rem;
                            padding: 0.2rem 1.3rem 0.2rem 0.3rem;
                            display: flex;
                            align-items: center;
                            font-size: 1.2rem;
                            line-height: 1.5rem;
                            img {
                                margin-right: 0.6rem;
                            }
                        }
                    }
                    &:nth-child(2) {
                        flex: 1;
                        text-align: left;
                    }
                }
                &:not(:last-child) {
                    position: relative;
                    &::after {
                        content: "";
                        height: 1px;
                        width: 100%;
                        background: $border-linear;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .job-opportunities-section {
        padding-bottom: 4.5rem;
        .section-content {
            .job-list {
                padding: 4rem 0;
                .job-item {
                    flex-direction: column;
                    &>div {
                        text-align: center;
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        &:first-child {
                            padding-bottom: 2rem;
                        }
                        &:nth-child(2) {
                            font-size: 2rem;
                            strong {
                                font-weight: 400;
                            }
                        }
                        &:nth-child(3) {
                            font-size: 1.6rem;
                        }
                        &:last-child {
                            padding-top: 2rem;
                        }
                    }
                }
            }
        }
    }
}