.vision-section {
    .section-content {
        padding-top: 2rem;
        .col-lg-6 {
            &:first-child {
                &>div {
                    position: relative;
                    h3 {
                        position: absolute;
                        width: 30rem;
                        left: 50%;
                        top: 50%;
                        margin-left: -15rem;
                        margin-top: 0.5rem;
                    }
                }
            }
        }
        .row {
            &::after {
                content: "";
                height: 1px;
                width: 100%;
                background: linear-gradient(270deg, #FFFFFF 0%, #E3E3E3 51.39%, #FFFFFF 100%);
            }
            &.last-row {
                &::after {
                    display: none;
                }
            }
            h6 {
                margin: 0;
            }
        }
        .col {
            padding: 2rem 1.5rem;
            line-height: 2.3rem;
            &:first-child {
                max-width: 10.6rem;
            }
            &:last-child {
                flex-grow: 1;
                flex-basis: 0;
            }
        }
    }
    dl {
        display: grid;
        grid-template-columns: max-content auto;
    }
    dt {
        grid-column-start: 1;
        font-size: 1.6rem;
        font-weight: 500;
    }
    dd {
        grid-column-start: 2;
        padding-left: 2rem;
    }
}

@media only screen and (max-width: 767px) {
    .vision-section {
        .section-content {
            .col-lg-6 {
                &:first-child {
                    margin: 0;
                    padding: 0.5rem 1.2rem;
                    &>div h3 {
                        width: 13rem;
                        margin-left: -6.5rem;
                        margin-top: 0;
                        font-size: 1.8rem;
                        br {
                            display: inline;
                        }
                    }
                }
                .row {
                    flex-direction: column;
                    .col {
                        width: 100%;
                        max-width: 100%;
                        text-align: center;
                        &:first-child {
                            padding-top: 4rem;
                            padding-bottom: 1.5rem;
                        }
                        &:last-child {
                            padding-top: 0;
                            padding-bottom: 4rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {
    .vision-section {
        .section-content {
            .col-lg-6 {
                &:first-child {
                    &>div h3 {
                        font-size: 2.8rem;
                        width: 21rem;
                        margin-left: -10.5rem;
                    }
                }
            }
        }
    }
}

html[lang='vi'] {
    .vision-section {
        .section-content {
            &>div:first-child {
                h3 {
                    font-size: 2.1rem;
                    @media only screen and (max-width: 767px) {
                        font-size: 1.6rem;
                        width: 18rem;
                        margin-left: -9rem;
                    }
                }
            }
        }
    }
}