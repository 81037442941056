html {
    &[lang='en'] {
        body {
            font-family: Gothic, sans-serif;
        }
    }
    &[lang='ja'],
    &[lang='ko'] {
        body {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-weight: 500;
            }
            .choose-accom-section {
                .section-content {
                    h5 {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}