@import "../../../../styles/variables";
@import "../../../../styles/mixin";

.pms-intro-section {
    .section-header {
        h6 {
            margin: 0;
        }
    }

    p {
        max-width: 80rem;
        margin: auto;
        margin-bottom: 4rem;
    }

    .content-center {
        &>div {
            max-width: 76.5rem;
            width: 76.5rem;
            margin-top: 3rem;
        }
    }

    .row {
        .col {
            text-align: left;

            &:first-child {

                max-width: 25.1rem;
                font-size: 1.8rem;
                font-weight: 500;
                margin-right: 2.5rem;

                img {
                    margin-right: 1.6rem;
                }
            }

            &:last-child {
                padding-left: 2rem;
                margin: 0;

                ul {
                    list-style: disc;
                    margin: 2rem 0 1rem;

                    li {
                        padding-bottom: 1rem;
                    }
                }
            }
        }

        &:after {
            content: "";
            height: 1px;
            width: 100%;
            background: linear-gradient(270deg,
                #ffffff 0%,
                #e3e3e3 51.39%,
                #ffffff 100%);
            display: block;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }



    @media only screen and (max-width: 767px) {
        .pms-images-system {
            margin: 0 -1.5rem;
        }

        .content-center {
            &>div {
                margin-bottom: 0;

                .row {
                    display: block;
                    margin: 0;
                    margin-bottom: 4rem;

                    &:after {
                        display: none;
                    }

                    .col {
                        padding: 0;

                        &:first-child {
                            img {
                                display: none;
                            }
                        }

                        &:last-child {
                            margin-bottom: 4rem;
                            padding-left: 2rem;

                            ul {
                                margin-top: 1.5rem;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        dl {
            display: block;
            margin-bottom: 0;

            dd {
                margin-bottom: 4rem;

                &:after {
                    display: none;
                }

                ul {
                    margin-top: 1.5rem;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            dt {
                img {
                    display: none;
                }
            }
        }
    }
}

html[lang="vi"] {
    .pms-intro-section {
        .row {
            .col {

                &:first-child {
                    max-width: 30rem;
                }
            }
        }
    }
}

.pms-just-in-time-section {
    z-index: 3;
    padding-bottom: 13.5rem;

    &::before,
    &::after {
        display: none;
    }

    .row {
        [class*="col-"] {
            align-self: center;
            margin-top: 8rem;
            padding: 0;

            &.align-right,
            &.align-left {
                padding: 0 3.5rem;
                line-height: 1.4;

                .p-title {
                    margin-bottom: 1.5rem;
                }
            }
        }

        .big-img {
            margin-top: 4rem;
            align-self: flex-start;

            img {
                margin-bottom: 1rem;
            }
        }

        ul {
            list-style: disc;
            padding-left: 3.5rem;

            li {
                padding-top: 1.5rem;
                padding-left: 0.5rem;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .align-left {
            padding: 0 2rem !important;
            margin-top: 3rem !important;
        }

        .order-5,
        .order-7 {
            text-align: center !important;
            margin-top: 5rem !important;
        }

        .order-3 {
            text-align: center !important;
            margin: 0 !important;

            &:before {
                content: "";
                height: 0.1rem;
                display: block;
                background: linear-gradient(270deg,
                    #ffffff 0%,
                    #e3e3e3 51.39%,
                    #ffffff 100%);
                margin: 5rem 0;
            }
        }

        .big-img {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

.pms-afe-section {
    position: relative;
    padding-top: 29.5rem;
    padding-bottom: 15rem;
    margin-top: -22.5rem;
    background: url("/images/home/section-line.svg") center -1px no-repeat,
        linear-gradient(180deg, $background-color-lightblue 0px, #fff 55rem);
    background-size: auto, 100%;

    &::before,
    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: url("/images/email-bg.svg") -418px 0 no-repeat;
        display: block !important;
        background-size: 878px;
        border: none;
        z-index: -1;
    }

    &::before {
        transform: rotateY(180deg);
    }

    &::after {
        transform: none;
    }

    .container {
        //max-width: 46rem;
    }

    @media only screen and (max-width: 767px) {
        @include mobile-contact-section;
    }

    @media only screen and (max-width: 991.98px) and (min-width: 768px) {

        &::before,
        &::after {
            background-position: -527px 0;
        }
    }
}