@import "../../../styles/variables";
@import "../../../styles/mixin";

.home-banner {
    padding: 0;

    .react-parallax {
        height: 47rem;

        .react-parallax-bgimage {
            //width: auto !important;
            margin-top: -9rem;
        }

        .parallax-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &>div {
                position: relative;
                top: -2rem;
                padding: 0 2rem;

                * {
                    color: white;
                }
            }
        }
    }

    .slick-slider {
        .slick-slide {
            height: 47rem;

            img {
                width: 100%;
            }

            .header-line {
                margin: 2rem 0 1.5rem;
            }

            h1,
            h2 {
                margin-bottom: 1.2rem;
                line-height: 1;
                text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
            }

            h2 {
                font-size: 4.2rem;
            }

            .parallax-content {
                img {
                    margin: 1.5rem 0 0.6rem;
                }
            }
        }

        .slick-prev {
            left: 0;
            display: none !important;
        }

        .slick-next {
            right: 0;
            display: none !important;
        }

        .slick-dots {
            bottom: 3rem;

            li {
                button {
                    height: 12px;
                    width: 12px;
                    margin: auto; //border: 1px solid #F0F0F0;
                    border-radius: 7.5px;
                    box-shadow: inset 0 4px 10px 2px rgba(0, 0, 0, 0.5);

                    &:before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background-color: white;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

.simple-intuitive-section {
    img {
        margin-top: 3rem;

        &.for-mobile {
            display: none;
        }
    }
}

.two-things-section {
    z-index: 3;

    &::before,
    &::after {
        display: none;
    }

    .section-content {
        img {
            padding: 3.5rem 0 5rem;
        }

        .cost-reduction-img {
            transform: translateX(12%);
        }
    }
}

.all-in-one-section {
    padding-top: 27rem;
    margin-top: -22.5rem;
    background: url("/images/home/section-line.svg") center -1px no-repeat,
        url("/images/home/seamless-solution-bg.svg") calc(100% + 250px) 35% no-repeat,
        url("/images/home/seamless-solution-bg.svg") -315px 35% no-repeat,
        linear-gradient(180deg, $background-color-lightblue 0px, #fff 44rem);

    .row {
        justify-content: center;
    }
}

.all-in-one-section,
.your-friend-confidant-section {
    .section-content {

        .col-md-4,
        .col-md-6 {
            padding: 0 3.5rem;

            &:nth-child(n + 4) {
                .card-body {
                    border-top: $primary-border;
                    padding-top: 4rem;
                }
            }
        }

        .card-body {
            border: 0;
            align-items: center;
            padding-bottom: 1.5rem;
            padding-left: 0;
            padding-right: 0;

            .item-icon {
                @include item-icon(7.5rem);
                margin-bottom: 2rem;
            }

            .p-content {
                ul {
                    list-style: disc;
                    margin: 0;
                    text-align: left;

                    li {
                        line-height: 1.2;
                        padding-bottom: 1rem;
                    }
                }

                a {
                    font-size: 1.5rem;
                    font-weight: 600;
                    line-height: 1;
                    padding-bottom: 0.3rem;

                    img {
                        margin-left: 0.8rem;
                    }
                }
            }
        }
    }

    .quote {
        // font-size: 2.4rem;
    }
}

@media only screen and (max-width: 767px) {

    /* Small devices (phones, 600px and down) */
    body {
        .home-banner {
            .slick-slider {
                .slick-slide {

                    h2,
                    h1 {
                        font-size: 2.9rem;
                        line-height: 1.3;
                    }
                }
            }
        }

        .simple-intuitive-section {
            img {
                &.for-mobile {
                    display: block;
                }

                &.for-desktop {
                    display: none;
                }
            }
        }

        .two-things-section {
            overflow: hidden;

            .section-header {
                padding-bottom: 0;
            }

            .section-content {
                img {
                    padding: 3.5rem 2rem 3.5rem; //height: 18.1rem;
                    margin: 0;
                }

                .cost-reduction-img {
                    transform: translateX(10%);
                }
            }
        }

        .all-in-one-section {
            padding-top: 27rem;
            margin-top: -22.5rem;
            background: url("/images/home/section-line.svg") center -1px no-repeat,
                url("/images/home/seamless-solution-bg.svg") calc(100% + 370px) 0% repeat-y,
                url("/images/home/seamless-solution-bg.svg") -515px 0% repeat-y,
                linear-gradient(180deg, $background-color-lightblue 0px, #fff 44rem);
            background-size: auto, auto 100%, auto 100%, auto;
        }

        .all-in-one-section,
        .your-friend-confidant-section {
            .section-content {

                .col-md-4,
                .col-md-6 {
                    &:nth-child(n + 2) {
                        .card-body {
                            position: relative;
                            padding-top: 4rem;
                            border-top: none;

                            &::after {
                                content: "";
                                height: 1px;
                                width: 100%;
                                background: linear-gradient(270deg,
                                    #ffffff 0%,
                                    #e3e3e3 51.39%,
                                    #ffffff 100%);
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }

        .your-friend-confidant-section {
            .card-body {
                padding-bottom: 0.3rem !important;
            }
        }
    }

    .management-system {
        margin: 0 -2rem;

        img {
            width: auto;
        }
    }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {

    /*Extra small devices (tablet/phone, from 768 to 991px) ipad 768-1024*/
    .two-things-section {
        .section-content {
            img {
                height: 25rem;
            }

            br {
                display: none;
            }
        }
    }

    .all-in-one-section {
        .section-content {

            .col-md-4,
            .col-md-6 {
                &:nth-child(n + 3) {
                    .card-body {
                        position: relative;
                        padding-top: 4rem;
                        border-top: none;

                        &::after {
                            content: "";
                            height: 1px;
                            width: 100%;
                            background: linear-gradient(270deg,
                                #ffffff 0%,
                                #e3e3e3 51.39%,
                                #ffffff 100%);
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    .your-friend-confidant-section {
        .section-content {
            .col-md-4 {
                padding: 0 1.5rem;
            }

            br {
                display: none;
            }
        }
    }
}